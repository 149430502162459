import React from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';

const ReferenceCard = ({ points = [], reversed, backgroundImage }) => (
  <div
    className={`container mx-auto w-full bg-white-100 shadow-100 flex flex-col p-4 space-y-4 ${
      reversed ? 'md:flex-row-reverse' : 'md:flex-row'
    } md:space-y-0`}
  >
    <div className={`w-full md:w-1/2 ${reversed ? 'md:pl-4' : 'md:pr-4'}`}>
      <Image
        alt={points[0]}
        className="object-cover object-bottom h-full w-full"
        image={backgroundImage}
      />
    </div>
    <div
      className={`md:flex-1 ${
        reversed ? 'md:pr-4' : 'md:pl-4'
      } flex items-center`}
    >
      <ul className="list-disc list-outside pl-4 text-black-100 space-y-6 flex flex-col">
        {points.split('//').map((text, index) => (
          <li className="project-type-text-sm sm:project-type-text" key={index}>
            {text}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

ReferenceCard.propTypes = {
  points: PropTypes.string.isRequired,
  reversed: PropTypes.bool,
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired
};

export default ReferenceCard;
