import { graphql } from 'gatsby';

import References from './template';

export const pageQuery = graphql`
  query ReferencesTemplate($language: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "References" }
        language: { eq: $language }
      }
    ) {
      frontmatter {
        pageTitle
        header {
          title
          text
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 4000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        marketing {
          title
          subtitle
          points
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        strategy {
          title
          subtitle
          points
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        science {
          title
          subtitle
          points
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        industry {
          title
          subtitle
          points
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default References;
