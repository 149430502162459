import React from 'react';
import PropTypes from 'prop-types';

import SectionTitleWrapper from '../SectionTitleWrapper';
import ReferencCard from '../ReferenceCard/template';

const ReferenceSection = ({
  title,
  subtitle,
  backgroundImage,
  points,
  reversed
}) => (
  <SectionTitleWrapper title={title} subtitle={subtitle}>
    <div className="px-4">
      <ReferencCard
        points={points}
        backgroundImage={backgroundImage}
        reversed={reversed}
      />
    </div>
  </SectionTitleWrapper>
);

ReferenceSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.string).isRequired,
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired
      }).isRequired
    })
  ]).isRequired,
  reversed: PropTypes.bool
};

export default ReferenceSection;
