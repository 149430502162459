import React from 'react';
import PropTypes from 'prop-types';

import HeaderSection from '../../components/HeaderSection';
import ReferenceSection from '../../components/ReferenceSection';

const ReferencesTemplate = ({
  header,
  marketing,
  strategy,
  science,
  industry
}) => (
  <>
    <HeaderSection {...header} />
    <ReferenceSection {...marketing} />
    <ReferenceSection {...strategy} reversed />
    <ReferenceSection {...industry} />
    <ReferenceSection {...science} reversed />
  </>
);

ReferencesTemplate.propTypes = {
  language: PropTypes.string.isRequired,
  header: PropTypes.object.isRequired,
  marketing: PropTypes.object.isRequired,
  strategy: PropTypes.object.isRequired,
  science: PropTypes.object.isRequired,
  industry: PropTypes.object.isRequired
};

export default ReferencesTemplate;
